import React from 'react';

function App() {
  return (
    <div className="bg-stone-900 text-xs text-stone-100">
      <header className="w-full md:w-1/2 lg:w-1/3 flex justify-around items-center p-2 mx-auto text-white">
        <a href="#about" className="">About</a>
        <a href="#experience" className="">Experience</a>
        <a href="#projects" className="">Projects</a>
      </header>

      {/* Hero Section */}
      <div id='hero-section' className="h-screen bg- flex items-center justify-center text-center">
        <div>
          <p className="text-xs md:text-lg lg:text-xl mt-2">Hello, I'm Michael. A passionate Full-Stack Developer</p>
          <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold"></h1>
        </div>
      </div>

      {/* About Section */}
      <section className="w-full py-12 px-4 bg-stone-800" id="about">
        <div className="max-w-3xl mx-auto">
          <h2 className="text-2xl md:text-3xl font-semibold mb-4">About</h2>

          <h3 className="text-xl md:text-2xl font-semibold mb-2">Education</h3>
          <p className="mb-2">Bachelor’s Degree in Finance | Mankato State University, Mankato, MN (2018-2023)</p>
          <p className="mb-6">Independent Study in Computer Science and Blockchain | Self-Taught (2021 - Current)</p>

          <h3 className="text-xl md:text-2xl font-semibold mb-2">Skills and Development</h3>

          <h4 className="font-semibold mb-2">Technical Skills</h4>
          <ul className="list-disc list-inside ml-5 mb-4">
            <li>Programming Languages: Python, JavaScript, TypeScript, Solidity</li>
            <li>Blockchain Development: ERC-20 tokens, Ethereum, NFTs, DeFi applications, smart contracts (Solidity)</li>
            <li>Blockchain Tools: Remix, Truffle, Web3.js, Ganache, Hardhat</li>
            <li>Web Development: Node.js, React, HTML/CSS, Git, APIs</li>
            <li>Data Analysis Tools: SQL, JD Edwards</li>
            <li>Blockchain Analytics: Etherscan, Blockchair (for transaction monitoring and forensic investigations)</li>
            <li>Database Management: SQL, MySQL</li>
            <li>Version Control: Git, GitHub</li>
          </ul>

          <h4 className="text-xl font-semibold mb-2">Professional Skills</h4>
          <ul className="list-disc list-inside ml-5">
            <li>Financial Analysis: Financial modeling, forecasting, budgeting, risk management</li>
            <li>Blockchain Forensics: Analyzing blockchain transactions and smart contract audits</li>
            <li>Problem Solving: Analytical thinking, debugging, data-driven decision-making</li>
            <li>Project Management: Agile methodologies, sprint planning, team collaboration, workflow optimization</li>
            <li>Client Relations: Proven success in customer service and consultative sales processes</li>
          </ul>
        </div>
      </section>
      {/* Experience Section */}
      <section className="py-12 px-4 bg-gray-100 text-gray-800" id="experience">
        <div className="max-w-3xl mx-auto">
          <h2 className="text-3xl md:text-4xl font-semibold mb-6 text-gray-900">Work Experience</h2>

          <div className="mb-10">
            <h3 className="text-2xl md:text-3xl font-semibold text-gray-900">Construction Subcontractor | Self-Employed (2023 - Current)</h3>
            <ul className="list-disc list-inside ml-5 mt-4 text-lg md:text-xl">
              <li>Managed subcontractor relationships, timelines, and safety standards.</li>
              <li>Developed cost-effective solutions to reduce operational expenses.</li>
            </ul>
          </div>

          <div className="mb-10">
            <h3 className="text-2xl md:text-3xl font-semibold text-gray-900">Data Analyst | Post Consumer Brands (2022 - 2023)</h3>
            <ul className="list-disc list-inside ml-5 mt-4 text-lg md:text-xl">
              <li>Analyzed large datasets to improve data integrity and decision-making.</li>
              <li>Collaborated with teams to execute key projects aligned with business goals.</li>
            </ul>
          </div>

          <div>
            <h3 className="text-2xl md:text-3xl font-semibold text-gray-900">Relationship Banker | Huntington Bank (2021 - 2022)</h3>
            <ul className="list-disc list-inside ml-5 mt-4 text-lg md:text-xl">
              <li>Proactively identified financial solutions to meet customer needs.</li>
              <li>Received awards for outstanding sales and customer satisfaction.</li>
            </ul>
          </div>
        </div>
      </section>


      {/* Projects Section */}
      {/* <section className="py-12 px-4 bg-gray-200" id="projects">
        <div className="max-w-3xl mx-auto">
          <h2 className="text-2xl md:text-3xl font-semibold mb-6">Projects</h2>

          <div className="mb-8">
            <img src="social-penguin-image.png" alt="Social Penguin" className="w-full h-48 object-cover mb-4" />
            <h3 className="text-xl md:text-2xl font-semibold">Social Penguin</h3>
            <p>A decentralized social media platform.</p>
            <a href="https://github.com/michaelbenolkin/social-penguin" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">View Project</a>
          </div>

          <div className="mb-8">
            <img src="hydron-finance-image.png" alt="Hydron Finance" className="w-full h-48 object-cover mb-4" />
            <h3 className="text-xl md:text-2xl font-semibold">Hydron Finance</h3>
            <p>A DeFi protocol focusing on yield optimization.</p>
            <a href="https://github.com/michaelbenolkin/hydron-finance" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">View Project</a>
          </div>

          <div>
            <img src="gridmaster-image.png" alt="Gridmaster" className="w-full h-48 object-cover mb-4" />
            <h3 className="text-xl md:text-2xl font-semibold">Gridmaster</h3>
            <p>A blockchain-based energy management system.</p>
            <a href="https://github.com/michaelbenolkin/gridmaster" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">View Project</a>
          </div>
        </div>
      </section> */}
    </div>
  );
}

export default App;
